<!-- eslint-disable sonarjs/no-duplicate-string -->
<template>
  <Sect id="SectionApvaSteps">
    <h2>Kaip gauti paramą?</h2>
    <Wrap class="lg">
      <div class="mainContainer">
        <!-- <HorizontalScroll v-if="steps" :itemsCount="steps.length"> -->
        <!-- <div ref="projects" class="projects"> -->

        <CardApvaStep
          v-for="(post, index) in steps"
          :key="index"
          class="card"
          :step="post" />

      <!-- </div> -->
      <!-- </HorizontalScroll> -->
      </div>
    </Wrap>
  </Sect>
</template>

<script>
import CardApvaStep from './CardApvaStep'

export default {
  components: { CardApvaStep },
  data() {
    return {
      steps: [
        {
          number: '01',
          title: 'Išsirinkite jums tinkamiausią saulės elektrinės galią',
          image: '../../../assets/apvaSteps/1.jpg',
          // eslint-disable-next-line quotes
          text: `Visų pirma, svarbu tinkamai įsivertinti reikiamą saulės elektrinės galios kiekį, kuris atitinka jūsų elektros energijos suvartojimą. Pasinaudoję „Saulės bendruomenės“, <a href="https://saulesbendruomene.lt/#Calculator" target="_blank" title="skaičiuoklė">skaičiuokle</a> galėsite sužinoti ne tik jums rekomenduojamą elektrinės galią, bet ir investicijos dydį bei atsipirkimo laikotarpį.`,
        },
        {
          number: '02',
          title: 'Rezervuokite elektrinės dalį',
          image: require('../../../assets/apvaSteps/2.jpg'),
          // eslint-disable-next-line quotes
          text: `Rezervuokite norimą elektrinės dalį viename iš „Saulės bendruomenės“ saulės parkų. Visą informaciją apie mūsų saulės parkus ir rezervaciją rasite <a href="/saules-parkai" target="_blank" title="Saulės parkai">čia</a>.`,
        },
        {
          number: '03',
          title: 'Įsigykite saulės elektrinę',
          image: require('../../../assets/apvaSteps/3.jpg'),
          // eslint-disable-next-line quotes
          text: `Kad galėtumėte įsigyti rezervuotą saulės elektrinės dalį, turite būti sumokėję avansinį mokestį ir taip patvirtinti sudarytą sutartį. Sumokėję avansą galite laukti saulės parko statybų pabaigos (jeigu parkas dar nepastatytas) ir elektrinės perdavimo klientams.`,
        },
        {
          number: '04',
          title: 'Parkas pradeda veikti',
          image: require('../../../assets/apvaSteps/4.jpg'),
          // eslint-disable-next-line quotes
          text: `Pasibaigus saulės parko rangos darbams ir parkui pradėjus generuoti elektros energiją, jums bus suformuotas galutinio mokėjimo prašymas. Atlikę mokėjimą, gausite visus reikiamus dokumentus, susijusius su jūsų saulės parko dalimi.`,
        },
        {
          number: '05',
          title: 'Pateikite paraišką APVA paramai gauti',
          image: require('../../../assets/apvaSteps/5.jpg'),
          // eslint-disable-next-line quotes
          text: `Įsigiję saulės elektrinę užpildykite ir pateikite paraišką APVA paramai gauti. Paraišką pateikti galite APVA internetinėje svetainėje: <a href="https://apvis.apva.lt/" target="_blank" title="APVA parama">apvis.apva.lt</a>. Prisijungę prie savo paskyros, reikiamus dokumentus pateikite APVIS sistemoje. „Saulės bendruomenės“ pasirūpins šių dokumentų perdavimu jums. Čia galite pasitikrinti išlaidų kompensavimo prašymui reikalingų dokumentų sąrašą.`,
        },
        {
          number: '06',
          title: 'Susigrąžinkite virš 35% investicijos',
          image: require('../../../assets/apvaSteps/6.jpg'),
          // eslint-disable-next-line quotes
          text: `Sėkmingai pateikus išlaidų kompensavimo prašymą, APVA jums išmokės nustatyto dydžio kompensaciją per 60 kalendorinių dienų nuo dokumentų pateikimo.`,
        },
      ],
    }
  },
}
</script>

<style lang="scss">
.Sect {
  margin-top: 0;
}

#SectionApvaSteps {
  background: $white;
  padding-bottom: 6rem;
  padding-top: 8rem;

  .mainContainer {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, 1fr);

    /* Medium screens (desktop): two cards per row */
    @include md {
      grid-template-columns: repeat(2, 1fr);
    }

    /* Extra large screens: three cards per row */
    @include xxl {
      grid-template-columns: repeat(3, 1fr);
    }

    .card {
      border: 1px solid #ccc;
      height: 100%; /* This will make all cards match the height of the tallest one */
      padding: 1rem;
    }
  }

  h2 {
    @include dotAbove(1.5rem);
    margin-bottom: 4rem;
  }

  .viewMore {
    display: flex;
    justify-content: center;
    margin-top: 4rem;

    > a {
      width: 18rem;
    }
  }
}
</style>
